<template>
    <div class="empty-container bigEntrance" :class="[type, $mq, customClass]">
        <div class="empty-page-content hide-scrollbar">
            <div class="content-container">
                <div class="andy-face tossing"></div>
                <div class="content-message">
                    <div class="title-content" v-html="title" :class="{ 'no-btn': buttonText == undefined }"></div>
                    <div class="description" v-html="description"></div>
                </div>
            </div>

            <div class="button-action" v-if="(buttonText != undefined && buttonType == 'create' && userLogged && userLogged.password != null) || $route.name == 'ConfigPage' || $route.name == 'Home'">
                <button class="btn pulse" :class="buttonClass" @click="actionButton()" v-html="buttonText"></button>
                <!-- <Button :bType="buttonType" :customClass="buttonClass" :bLabel="buttonText" :bCallback="actionButton"></Button> -->
            </div>
            <div class="optional-link" v-if="link != undefined">
                <router-link :to="link">{{ linkText }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EmptyPage',
    props: {
        title: String,
        description: String,
        link: undefined,
        linkText: undefined,
        buttonText: undefined,
        buttonType: { type: String, default: 'create' },
        buttonClass: { type: String, default: 'add' },
        type: { type: String, default: 'default' },
        customClass: { type: String, default: '' }
    },
    computed: {
        userLogged() {
            var user = this.$store.getters['loginUser/getLocalEmployee']
            var roleUserLogged = this.$store.getters['employee/getEmployee'](user)
            return roleUserLogged
        }
    },
    data() {
        return {
            openReport: true
        }
    },
    methods: {
        actionButton() {
            this.$emit('buttonAction')
        }
    }
}
</script>

<style lang="scss" scoped>
.empty-container {
    @include display-flex();
    @include justify-content();
    @include align-items(center);
    width: 100%;
    // height: calc(100% - 70px);
    height: 90%;
    pointer-events: all;
    z-index: 10;
    display: grid;
    place-items: center;

    .empty-page-content {
        // @include background($image: img('ellipse_neutro_01.svg'), $size: 80%);
        // @include display-flex();
        // @include flex-direction(column);
        // @include align-items(flex-start);
        // @include justify-content(center);
        padding-left: 0px;
        // z-index: 2;
        height: max-content;
        width: 100%;
        text-align: left;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2em;

        .content-container {
            height: max-content;
            width: 100%;
            max-width: 100%;
        }

        .andy-face {
            @include background($image: img('alex_carita_05.svg'));
            content: '';
            position: absolute;

            width: 81px;
            height: 71px;
            right: -31px;
            top: -50px;
            z-index: 20;
        }

        .content-message {
            background-color: $color-neutral-400;
            padding: 1.7em;
            border-radius: 15px;
            width: 500px;
            max-width: 500px;

            .title-content {
                // @include font-size(xl);
                font-family: $text-bold;
                color: $neutro-s90;
                font-size: 25px;
            }
            .description {
                // @include font-size(m);
                font-size: 20px;
                // font-family: $conden;
                font-family: $text-light;
                color: $neutro-s90;
                padding-top: 1em;
                line-height: 1.2em;
            }
        }

        .optional-link {
            @include font-size(sm);
            font-family: $text-medium;
            color: $main-s10;
            padding-top: 6px;

            width: 100%;
            display: grid;
            place-items: center;
        }

        .button-container {
            display: flex !important;
        }
        .button-action {
            // padding-top: 30px;
            width: 100%;
            display: grid;
            place-items: center;

            .btn {
                animation-iteration-count: 2;
                -webkit-animation-iteration-count: 2;
                margin: 0;

                &.add {
                    @include background($image: img('add_ffffff.svg'), $size: 12px, $position: 8px center);
                    // background-color: $done;
                    padding-left: 25px;
                    border-radius: 0.3em;
                }
            }
        }

        .link {
            color: white;
            font-size: 20px;
        }
    }

    // NEW UPDATE OF EMPTY PAGE
    .empty-page-content .andy-face {
        @include background($image: img('alex_carita_01.svg'));
        content: '';
    }
    .empty-page-content {
        // @include background($image: img('ellipse_neutro_02.svg'), $size: 90%);
        @include align-items(flex-start);
        text-align: left;
        margin-left: 0px;
    }
    .title-content {
        // @include font-size(xl);
        font-family: $text-bold;
        color: $neutro-s90;
        height: auto;
        text-align: left;
    }
    .description {
        @include font-size(ml);
        font-family: $conden;
        color: $neutro-s90;
        height: auto;
        text-align: left;
    }
    .button-action {
        padding-top: unset;
        margin-bottom: 0px;

        .add {
            margin-right: 40px;
        }

        .btn:last-child {
            margin-right: 0px;
        }
    }

    &.printer {
        .empty-page-content {
            .andy-face {
                @include background($image: img('alex_carita_01.svg'));
                content: '';
            }
        }
    }

    &.login {
        .empty-page-content {
            @include background($image: img('ellipse_neutro_04.svg'), $size: 100%);

            .title-content {
                max-width: 305px;
                height: 46px;
                line-height: 27px;
            }
            .description {
                font-family: $conden;
                max-width: 350px;
            }
            .btn {
                margin: 0;

                &.add {
                    @include background($image: img('help_circle_sharp_2.svg'), $size: 21px, $position: 10px center);
                    background-color: $main;
                    border-radius: 60px;
                    padding-left: 35px;
                }
            }
        }
    }
    &.error {
        .empty-page-content {
            // @include background($image: img('ellipse_warning.svg'), $size: 100%);

            gap: 1.3em;

            .content-message {
                width: 350px;
                max-width: 350px;
                padding-left: 2em;
            }

            .button-action {
                .back {
                    // @include border-radius(rem(60px));
                    @include background($image: img('left_ffffff.svg'), $position: left 10px center, $size: 13px);
                    background-color: $main-s10;
                    padding-left: 28px;
                }
            }
        }
        .empty-page-content .andy-face {
            @include background($image: img('alex_carita_16.svg'));
            content: '';
            transform: scaleX(-1) !important;
            right: -18px;
            top: -41px;
        }
    }

    &.sensors-config {
        .empty-container {
            height: 100%;
        }
        .content-message {
            .title-content {
                line-height: 1.1em;
                width: 100%;
            }

            .description {
                width: 100%;
                max-width: none;
            }
        }

        @media screen and (max-width: 1023px) {
            .content-message {
                max-width: 300px;
            }
        }

        @media screen and (min-width: 1024px) {
            .content-message {
                width: 500px;
            }
            .description {
                font-size: 20px !important;
            }
        }
    }
}
</style>

<!-- PORTRAIT STYLES -->
<style lang="scss" scoped>
.empty-container.portrait {
    width: 100%;
    min-width: unset;
    height: 90%;
    // @include background($image: img('ellipse_neutro_01.svg'), $size: 100%, $position: top 40px center);
    display: grid;
    place-items: center;

    .empty-page-content {
        height: fit-content;

        // margin: 0 auto;
        // min-width: 100%;
        gap: 1.5em;
        width: 100%;
        max-width: 100%;

        @media screen and (max-width: 464px) {
            width: 85% !important;
        }

        .andy-face {
            width: 66.29px;
            right: -19px;
            top: -48px;
        }

        .content-message {
            padding: 1em;
            width: 100%;
        }

        .title-content,
        .description {
            width: 100% !important;
            max-width: none;
            font-size: 16px;
        }

        .title-content {
            //@include font-size(ml);
            font-size: 20px;
            padding-top: 0px;
        }

        .button-action {
            width: 100%;
            text-align: center;
        }
    }

    // &.record {
    //     .content {
    //         height: fit-content;
    //         min-height: calc(300px);
    //         background-image: none;
    //         padding: 20px 0px 0px !important;
    //         // max-width: 100%;
    //         margin: 0 auto;
    //         min-width: 100%;

    //         .andy-face {
    //             @include background($image: img('alex_carita_05.svg'), $size: 100%, $position: top 40px center);
    //             height: 200px;
    //             width: 90px;
    //             position: absolute;
    //             top: calc(50% - 150px + 10px);
    //             left: 5px;
    //             right: none;
    //         }
    //         &::before {
    //             // @include background($image: img('alex_carita_05.svg'), $size: 100%, $position: top 40px center);
    //             // height: 200px;
    //             // width: 90px;
    //             // position: absolute;
    //             // top: calc(50% - 150px + 10px);
    //             // left: 5px;
    //             // right: none;
    //         }
    //     }
    // }

    &.checklist {
        margin: 0px auto;
        height: calc(100% - 50px);
        .empty-page-content {
            padding: 0px;
        }
        background-position-y: center;
    }

    &.error {
        .empty-page-content {
            width: 100% !important;
            gap: 1em;

            @media screen and (max-width: 464px) {
                width: 85% !important;
            }

            .andy-face {
                transform: scaleX(-1);
                top: -43px;
            }

            .btn {
                font-size: 16px;
            }
        }
    }
}
</style>
